import classNames from "classnames";
import ShipmentStatus from "../../../enums/ShipmentStatus";
import classes from "./StatusProgress.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBox,
  faTruck,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import StatusProgressCircle from "../StatusProgressCircle";

export type StatusProgressProps = {
  status?: ShipmentStatus;
};

const StatusProgress = ({
  status = ShipmentStatus.Pre_Transit,
}: StatusProgressProps) => {
  let progress;
  switch (status) {
    case ShipmentStatus.New:
      progress = (
        <>
          <div className={classes.iconContainer}>
            <StatusProgressCircle status={status} />
            <FontAwesomeIcon icon={faBox} className={classes.icon} />
          </div>
        </>
      );
      break;
    case ShipmentStatus.Pre_Transit:
      progress = (
        <>
          <div className={classes.iconContainer}>
            <StatusProgressCircle status={status} />
            <FontAwesomeIcon icon={faBox} className={classes.icon} />
          </div>
        </>
      );
      break;

    case ShipmentStatus.In_Transit:
      progress = (
        <>
          <div className={classes.iconContainer}>
            <StatusProgressCircle status={status} />
            <FontAwesomeIcon icon={faTruck} className={classes.icon} />
          </div>
        </>
      );
      break;

    case ShipmentStatus.Out_For_Delivery:
      progress = (
        <>
          <div className={classes.iconContainer}>
            <StatusProgressCircle status={status} />
            <FontAwesomeIcon icon={faTruck} className={classes.icon} />
          </div>
        </>
      );
      break;

    case ShipmentStatus.Delivered:
    case ShipmentStatus.Available_For_Pickup:
      progress = (
        <>
          <div className={classNames(classes.iconContainer, classes.delivered)}>
            <StatusProgressCircle status={status} />
            <svg
              version="1.2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 454 482"
              className={classes.deliveredIcon}
            >
              <path
                id="Path 0"
                d="m218 72v96h-77.5c-42.6 0-77.5-0.2-77.5-0.5 0-0.3 4.8-10.1 10.6-21.8 5.8-11.6 14.9-29.5 20.2-39.7 6.9-13.1 11-19.8 14.2-23 2.5-2.5 6.5-5.6 9-6.8 2.5-1.2 6.8-2.6 9.5-3.1 2.8-0.5 24.5-0.9 91.5-1.1zm24.1-0.1l46.2 0.3c40.9 0.3 46.7 0.6 50.7 2.1 2.5 1 6.6 3.3 9.2 5.2 2.7 1.9 6.2 5.6 8 8.3 1.8 2.6 11.7 21.4 22 41.7 10.3 20.3 18.8 37.3 18.8 37.8 0 0.4-34.9 0.7-155 0.7zm-180.2 120.1h335.7l-0.1 87.8c-0.1 82.7-0.2 88-2 93.2-1 3.1-3.3 8-5.1 11-1.8 3.1-5 7.3-7.1 9.4-2.1 2.1-6.4 5.3-9.6 7.2-3.1 1.9-8.3 4.2-11.5 5.2-5.3 1.6-14.6 1.7-132.2 1.7-117.6 0-126.9-0.1-132.3-1.7-3.1-1-8.3-3.3-11.5-5.2-3.1-1.9-7.5-5.1-9.6-7.2-2.1-2.1-5.3-6.3-7.1-9.4-1.7-3-4-7.9-5.1-11-1.7-5.1-1.8-10.8-2.2-93.2zm229.6 39.2c-1.6 1.1-21.5 20.6-44.2 43.2l-41.3 41.1c-26.7-26.5-36.1-35.3-38-36.6-2.8-2-4.8-2.4-10-2.4-5.3 0-7.1 0.4-9.9 2.5-1.9 1.4-4.3 3.8-5.5 5.5-1.6 2.3-2.1 4.5-2.1 9.5 0 5.2 0.5 7.2 2.4 10 1.3 1.9 12.6 13.7 25 26.3 12.4 12.6 24.6 24.3 27.1 26 4 2.8 5.3 3.2 11 3.2 5.2 0 7.2-0.5 10-2.4 1.9-1.3 24.6-23.4 50.3-49 25.8-25.7 48.3-48.6 50-51.1 2.8-4 3.2-5.3 3.2-11 0-5.4-0.4-7.1-2.5-9.9-1.4-1.9-3.7-4.3-5.2-5.3-1.5-1-5.1-2-8-2.3-2.9-0.2-6.2-0.2-7.3 0.2-1.1 0.3-3.3 1.4-5 2.5z"
              />
            </svg>
          </div>
        </>
      );
      break;

    case ShipmentStatus.Return_To_Sender:
      progress = (
        <>
          <div className={classes.iconContainer}>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className={classes.icon}
            />
          </div>
        </>
      );
      break;

    case ShipmentStatus.Unknown:
      progress = (
        <>
          <div className={classes.iconContainer}>
            <FontAwesomeIcon icon={faBox} className={classes.icon} />
          </div>
        </>
      );
      break;

    default:
      progress = (
        <>
          <div className={classes.iconContainer}>
            <div className={classes.errorText}>
              <span>Error</span>
            </div>
          </div>
        </>
      );
      break;
  }

  return <div className={classes.main}>{progress}</div>;
};

export default StatusProgress;
