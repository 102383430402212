import React, { FC, useContext, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { getHostname } from "../../../../utils/location-utils";
import { isEmpty } from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import LinearProgress from "@mui/material/LinearProgress";
import { Alert, Box, Modal, Snackbar } from "@mui/material";

import { Status } from "../../../../enums/Status";
import { Steps } from "../../../../enums/Steps";
import { PDFLabel, RMAData } from "../../../../entities/RMAData";

import { OrderContext } from "../../../../providers/OrderProvider";

import { useSnackbar } from "../../../../hooks/useSnackBar";
import useGetRMAInformation from "../../../../services/useGetRMAInformation";
import useInvoiceData from "../../../../hooks/useInvoiceData";
import useCancelReturnAuthorization from "../../../../services/useCancelReturnAuthorization";
import useSettingsData from "../../../../hooks/useSettingsData";
import useCreateLabelInfo from "../../../../services/useCreateLabelInfo";
import useGetLabelInformation from "../../../../services/useGetLabelInformation";

import Row from "../../../../components/Common/Row";
import Column from "../../../../components/Common/Column";
import Button from "../../../../components/Common/Button";
import Title from "../../../../components/Common/Title";
import Paragraph from "../../../../components/Common/Paragraph";
import PrintLabel from "../../../../components/Returns/PrintLabel";
import ReturnDetails from "../../../../components/Returns/ReturnDetails";
import ReturnProduct from "../../../../components/Returns/ReturnProduct";
import RMASummary from "../../../../components/Returns/RMASummary";
import ReturnStatus from "../../../../components/Returns/ReturnStatus";
import ReturnStatusTag from "../../../../components/Returns/ReturnStatusTag";

import classes from "../../ReturnsPage.module.scss";
import { useSearchParams } from "react-router-dom";

type Step3Props = {
  handleSetStep: (step: Steps) => void;
  zipCode?: string;
};

interface PrintLabelErrors {
  [key: string]: boolean;
}

export const Step3: FC<Step3Props> = () => {
  const { rmaList, handleSetCurrentRma, currentRma, rmaItems } =
    useContext(OrderContext);
  const { getRMAInformationForm } = useGetRMAInformation();
  const { customerInfo, invoice } =
    useInvoiceData();
  const { createLabelInfo, isLabelInfoCreating } = useCreateLabelInfo();
  const { getLabelInfoForm, isFetchingLabels } = useGetLabelInformation();
  const { addSnack } = useSnackbar();
  const { cancelReturnAuthorization } = useCancelReturnAuthorization();
  const settings = useSettingsData();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [printLabelErrors, setPrintLabelErrors] = useState<PrintLabelErrors>(
    {}
  );
  const [open, setOpen] = React.useState(false);
  const [isPdfLabelArrayCreated, setIsPdfLabelArrayCreated] = useState(false);
  const [cancelItemId, setCancelItemId] = useState("");
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(
    rmaList.length > 0 ? rmaList[rmaList.length - 1].id : false
  );
  const [pdfLabelArray, setPdfLabelArray] = useState<PDFLabel[]>(
    sessionStorage.getItem("pdfLabelArray")
      ? JSON.parse(sessionStorage.getItem("pdfLabelArray") as string)
      : []
  );

  const orderNumber = useMemo(
    () => searchParams.get("order-number") ?? undefined,
    [searchParams]
  );

  const zipCode = useMemo(
    () => searchParams.get("zip-code") ?? undefined,
    [searchParams]
  );

  const createShippingBaseUrl = () => {
    const shippingBaseUrl = settings?.custrecord_ucp_shipping_base_url;
    if (!shippingBaseUrl) {
      return undefined;
    }
    return `${shippingBaseUrl}/order?order-number=${orderNumber}&zip-code=${zipCode}`;
  };

  const validStatuses = [
    Status.Refunded,
    Status.PendingRefund,
    Status.PendingReceipt,
    Status.PartiallyReceived,
    Status.PartiallyReceivedPendingRefund,
  ];

  const approvedReturnStatus = (status: string) =>
    validStatuses.includes(status as Status);

  const rmaWithoutLabel = rmaList.length > pdfLabelArray.length;

  const rmaListNotKeepItem = rmaList.filter((rma) =>
    rma.items.some(
      (item) =>
        item.itemtype === "InvtPart" &&
        item.custcol_ucp_return_option !== "Keep Item"
    )
  );

  const rmaToCreateFetchLabel = rmaListNotKeepItem.filter((rma) =>
    validStatuses.includes(rma.status as Status)
  );

  const rmaWithoutLabelList = rmaToCreateFetchLabel.filter(
    (rma) => !pdfLabelArray.some((label) => label.rmaID === rma.id)
  );

  const fetchAllPrintLabels = async (rmaItems: RMAData[]) => {
    try {
      const newPdfLabels = [];
      const existingIds = new Set();
      for (const item of rmaToCreateFetchLabel) {
        const response = await getLabelInfoForm({
          rmaID: item.id,
          domain: getHostname(),
        });
        const results = response?.result?.body?.results ?? [];

        if (results.length > 0) {
          const lastPDF = response.result.body.results.length - 1;
          const pdfLabel = response.result.body.results[lastPDF];

          if (!existingIds.has(pdfLabel.id)) {
            newPdfLabels.push(pdfLabel);
            existingIds.add(pdfLabel.id);
          }
        }
      }
      setPdfLabelArray(newPdfLabels);
      sessionStorage.setItem("pdfLabelArray", JSON.stringify(newPdfLabels));
    } catch (error) {
      console.error(error);
    } finally {
      setIsPdfLabelArrayCreated(true);
    }
  };

  useEffect(() => {
    if (rmaToCreateFetchLabel.length > 0) {
      fetchAllPrintLabels(rmaToCreateFetchLabel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRma.status, rmaWithoutLabel]);

  const createLabel = async (rmaId: string) => {
    try {
      const domain = getHostname();
      const addressId = settings?.custrecord_ucp_return_location;
      const from_address = {
        street1: customerInfo?.shipaddress1,
        city: customerInfo?.shipcity,
        state: customerInfo?.shipstate,
        zip: invoice?.shipzip ?? "",
        country: "US",
        company: customerInfo?.name,
        phone: customerInfo?.phone,
      };
      const parcel = {
        length: settings?.custrecord_ucp_returns_package_length_def ?? 10.2,
        width: settings?.custrecord_ucp_returns_package_width ?? 7.8,
        height: settings?.custrecord_ucp_returns_package_height_def ?? 4.3,
        weight: settings?.custrecord_ucp_returns_prod_weight_def ?? 2.2,
        box: settings?.custrecord_ucp_returns_box_weight_default ?? 4.2,
      };
      await createLabelInfo({
        from_address,
        parcel,
        rmaId,
        addressId: String(addressId),
        domain,
        custrecord_ucp_return_auth_form: String(
          settings?.custrecord_ucp_return_auth_form
        ),
        custrecord_ucp_shipping_base_url: createShippingBaseUrl(),
      });

      setPrintLabelErrors((prevErrors) => ({
        ...prevErrors,
        [rmaId]: false,
      }));
      await fetchAllPrintLabels(rmaToCreateFetchLabel);
    } catch (e) {
      console.error(e);
      setPrintLabelErrors((prevErrors) => ({
        ...prevErrors,
        [rmaId]: true,
      }));
    } finally {
      setIsPdfLabelArrayCreated(false);
    }
  };
  useEffect(() => {
    try {
      if (
        rmaToCreateFetchLabel &&
        isPdfLabelArrayCreated &&
        rmaWithoutLabelList.length > 0
      ) {
        rmaWithoutLabelList.forEach(async (rma) => {
          await createLabel(rma.id);
        });
      }
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRma.status, isPdfLabelArrayCreated]);

  const handleDeleteRmaModal = (rmaID: string) => {
    setCancelItemId(rmaID);
    setOpen(true);
  };

  const handleDeleteRma = async () => {
    try {
      setOpen(false);
      setLoading(true);
      await cancelReturnAuthorization({
        domain: getHostname(),
        rmaID: cancelItemId,
      });
      const resRMA = await getRMAInformationForm({
        orderNumber: invoice?.id ?? "",
        domain: getHostname(),
        zipCode: invoice?.shipzip ?? "",
      });
      if (!isEmpty(resRMA.results)) {
        handleSetCurrentRma(resRMA.results);
      }
      addSnack({
        display: true,
        severity: "success",
        message: "Return cancelled successfully!",
      });
      setSubmitted(true);
    } catch (error) {
      console.error(error);
      addSnack({
        display: true,
        severity: "error",
        message: "Unexpected error. Please try again.",
      });
      setSubmitted(false);
    } finally {
      setLoading(false);
    }
  };

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : false);
    };

  const toggleAccordionIcon = (productId: string) => {
    if (expandedAccordion === productId) {
      return <FontAwesomeIcon icon={faMinus} />;
    } else {
      return <FontAwesomeIcon icon={faPlus} />;
    }
  };

  return (
    <>
      {rmaList
        .slice()
        .reverse()
        .map((itemList) => (
          <Accordion
            sx={{
              boxShadow: "none",
              border: "1px solid #E5E7EB",
              marginTop: "1.5rem !important",
              fontSize: ".75rem",
              fontWeight: "600",
              borderRadius: "4px",
            }}
            expanded={expandedAccordion === itemList.id}
            onChange={handleAccordionChange(itemList.id)}
            key={itemList.id}
          >
            <AccordionSummary
              aria-controls={itemList.internalid}
              id={itemList.id}
              sx={{
                height: "56px",
                minHeight: "auto !important",
                padding: "0rem 2rem",
                color: "#ffffff",
                background:
                  "linear-gradient(106.64deg, var(--primary-darkest-color) 1.67%, var(--primary-darkest-color) 43.13%, var(--primary-darkest-color) 102.43%)",
                borderRadius: "4px",
              }}
              className={classNames(
                expandedAccordion === itemList.id
                  ? classes.summaryExpanded
                  : "",
                classes.rmaAccordionHeader
              )}
            >
              <div className={classes.returnDetailsAccordionHeader}>
                <Title kind="h2" size="xs">
                  Return&nbsp;#{itemList.id}
                </Title>
                <div className={classes.statusWrapper}>
                  <ReturnStatusTag status={itemList.status} />
                  <div className={classes.toggleIcon}>
                    {toggleAccordionIcon(itemList.id)}
                  </div>
                </div>
              </div>
            </AccordionSummary>

            <AccordionDetails
              className={classes.rmaAccordionDetails}
              sx={{
                padding: "1.5rem",
                backgroundColor: "#E5E7EB",
              }}
            >
              <Row>
                <Column width={60}>
                  {printLabelErrors[itemList.id] && (
                    <Box className={classes.fixedSnackbarWrapper}>
                      <Snackbar
                        open={true}
                        autoHideDuration={null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        className={classNames(classes.fixedSnackbar)}
                      >
                        <Alert
                          severity={"error"}
                          sx={{ width: "100%" }}
                          className={classes.fixedSnackbarAlert}
                        >
                          Unexpected error while processing creating label.
                          Please refresh the site to try again.
                        </Alert>
                      </Snackbar>
                    </Box>
                  )}
                  {itemList.items.some(
                    (item) =>
                      item.itemtype === "InvtPart" &&
                      item.custcol_ucp_return_option === "Keep Item"
                  ) ? (
                    <Box className={classes.fixedSnackbarWrapper}>
                      <Snackbar
                        open={true}
                        autoHideDuration={null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        className={classes.fixedSnackbar}
                      >
                        <Alert
                          severity={"info"}
                          sx={{ width: "100%" }}
                          className={classes.fixedSnackbarAlert}
                        >
                          You don't need to ship your products. We'll issue your
                          refund or gift card once we process your return.
                        </Alert>
                      </Snackbar>
                    </Box>
                  ) : null}
                  <section className={classes.returnItems}>
                    <div className={classes.returnWrapper}>
                      <div className={classes.returnContent}>
                        {rmaItems.map((product) => (
                          <ReturnProduct product={product} key={product.item} />
                        ))}
                      </div>
                    </div>
                  </section>
                  <ReturnDetails rmaData={itemList} />
                  {itemList.items.some(
                    (item) =>
                      item.itemtype === "InvtPart" &&
                      item.custcol_ucp_return_option !== "Keep Item" &&
                      approvedReturnStatus(itemList.status) &&
                      !printLabelErrors[itemList.id]
                  ) ? (
                    <>
                      <PrintLabel
                        rmaData={itemList}
                        isLabelInfoCreating={isLabelInfoCreating}
                        isFetchingLabels={isFetchingLabels}
                        pdfLabelData={
                          pdfLabelArray.find(
                            (label) => label.rmaID === itemList.id
                          ) || null
                        }
                      />
                      <ReturnStatus
                        rmaData={itemList}
                        isLabelInfoCreating={isLabelInfoCreating}
                        isFetchingLabels={isFetchingLabels}
                        pdfLabelData={
                          pdfLabelArray.find(
                            (label) => label.rmaID === itemList.id
                          ) || null
                        }
                      />
                    </>
                  ) : null}
                </Column>
                <Column width={40} className={classes.stickyContent}>
                  <RMASummary currentRma={itemList} rmaItems={rmaItems} />
                  {itemList.status === Status.PendingApproval ? (
                    <div className={classes.submitWrapper}>
                      <Button
                        kind="secondary"
                        className={classes.backButton}
                        onClick={() =>
                          handleDeleteRmaModal(itemList.internalid)
                        }
                        disabled={loading}
                      >
                        {loading && cancelItemId === itemList.internalid
                          ? "Cancelling Return..."
                          : submitted && cancelItemId === itemList.internalid
                          ? "Return Cancelled"
                          : "Cancel Return"}
                      </Button>
                      {loading && cancelItemId === itemList.internalid && (
                        <div className={classes.progressBar}>
                          <LinearProgress
                            sx={{
                              backgroundColor: "#ffffff",
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: "var(--headings-color);",
                              },
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ) : null}
                </Column>
              </Row>
            </AccordionDetails>
          </Accordion>
        ))}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classNames(classes.modal, classes.modalCancel)}>
          <Title kind="h3" size="l">
            You are cancelling this return
          </Title>
          <Paragraph className={classes.subTitle} size="xs">
            Are you sure?
          </Paragraph>
          <div className={classes.buttonWrapper}>
            <div>
              <Button
                onClick={handleDeleteRma}
                kind="primary"
                className={classes.closeModalButton}
              >
                Confirm
              </Button>
            </div>
            <div>
              <Button
                onClick={() => setOpen(false)}
                kind="secondary"
                className={classes.closeModalButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
