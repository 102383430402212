import { useEffect, useMemo, useState } from "react";
import { Alert } from "@mui/material";
import ReactGA from "react-ga4";

import "./App.scss";
import Router from "./router";
import LoadSpinner from "./components/Common/LoadSpinner";
import useAuth from "./hooks/useAuth";
import usePortalLookAndFeel from "./hooks/usePortalLookAndFeel";
import { usePortalLookAndFeelStorage } from "./hooks/usePortalLookAndFeelStorage";
import UnderMaintenance from "./pages/UnderMaintenance";

const App = () => {
  const {
    application,
    authenticate,
    isLoading: isAuthLoading,
    error: authError,
  } = useAuth();
  const {
    portalLookAndFeel,
    load: loadPortalLookAndFeel,
    error: portalLookAndFeelError,
  } = usePortalLookAndFeel();
  const { hasPortalLookAndFeelFromLocalStorage } =
    usePortalLookAndFeelStorage();

  const [isPortalLookAndFeelLoading, setPortalLookAndFeelLoading] = useState(
    !hasPortalLookAndFeelFromLocalStorage
  );

  const isLoading = useMemo(
    () => isAuthLoading || isPortalLookAndFeelLoading,
    [isAuthLoading, isPortalLookAndFeelLoading]
  );

  const error = useMemo(
    () => authError || portalLookAndFeelError,
    [authError, portalLookAndFeelError]
  );

  useEffect(() => {
    authenticate();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchPortalLookAndFeel = async () => {
      if (application) {
        await loadPortalLookAndFeel(
          isPortalLookAndFeelLoading,
          setPortalLookAndFeelLoading
        );
        setPortalLookAndFeelLoading(false);
      }
    };
    fetchPortalLookAndFeel();

    // eslint-disable-next-line
  }, [application?.lookAndFeelId]);

  // dont remove
  // eslint-disable-next-line
  console.log("version", "0.9.1");

  if (error) return <Alert severity="error">{error.message}</Alert>;

  if (isLoading) return <LoadSpinner />;

  if (application?.underMaintenance) {
    return (
      <UnderMaintenance
        underMaintenanceMessage={portalLookAndFeel.underMaintenance}
      />
    );
  }

  ReactGA.initialize([
    {
      trackingId: String(process.env.REACT_APP_GA_MEASUREMENT_ID),
    },
    {
      trackingId: String(
        portalLookAndFeel.custrecord_ucp_google_analytics_code
      ),
    },
  ]);

  return <Router />;
};

export default App;
